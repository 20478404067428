

import { defineComponent } from 'vue'
import { mapGetters, useStore } from 'vuex'
import NavItem from '@/components/NavItem.vue'
import NavGroupHeader from '@/components/NavGroupHeader.vue'

export default defineComponent({
  name: 'Navigation',
  data() {
    return {
      isV2ResourcesOpen: false,
      isV2FromUsersOpen: false,
    }
  },
  computed: {
    ...mapGetters([
      'environment',
      'packageVersion',
      'isAdmin',
    ]),
  },
  methods: {
    getPackageVersionText(): string {
      return `${ this.mixWB('VERSION_X', [this.packageVersion]) } @ ${ this.environment }`
    },
    firebaseLink(): { title: string, url: string } {
      return this.environment === 'production'
        ? {
          title: this.mixWB('W: Firebase (PROD)'),
          url: 'https://console.firebase.google.com/u/0/project/milva-pro-prod/firestore/data/~2FAccounts',
        }
        : {
          title: this.mixWB('W: Firebase (DEV)'),
          url: 'https://console.firebase.google.com/u/0/project/milva-pro-dev/firestore/data/~2FAccounts',
        }
    },
  },
  components: {
    NavItem,
    NavGroupHeader,
  },
  setup() {
    const store = useStore()
    return {
      onLogOut() {
        store.dispatch('logout')
      },
    }
  },
})
