
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NavGroupHeader',
  props: {
    showOpenIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onClick() {
      if (this.showOpenIcon) {
        this.$emit('header-click')
      }
    },
  },
})
