
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NavItem',
  props: {
    to: [Object, String],
    isExternal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
})

