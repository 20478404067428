
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { TUser } from '@/types'

export default defineComponent({
  setup() {
    const store = useStore()

    return {
      user: computed<TUser>(() => store.getters.currentUser),
    }
  },
})

