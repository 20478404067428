
import { defineComponent } from 'vue'
import Header from '@/components/Header.vue'
import Navigation from '@/components/Navigation.vue'

export default defineComponent({
  components: {
    Header,
    Navigation,
  },
})

